import 'babel-polyfill';

import Vue from 'vue';
import Vuex from 'vuex';
import VuexORM from '@vuex-orm/core';
import VuexORMAxios from '@vuex-orm/plugin-axios';
import axios from 'axios';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { createInertiaApp, Link, router } from '@inertiajs/vue2';
import PortalVue from 'portal-vue';
import VueFullscreen from 'vue-fullscreen';
import VueSocialSharing from 'vue-social-sharing';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import numeral from 'numeral';
import VueClipboard from 'vue-clipboard2';
import VueScrollTo from 'vue-scrollto';
import Echo from 'laravel-echo';
import CookieConsent from './Plugins/cookieconsent-plugin.js';
import ZiggyPlugin from './Plugins/ziggy-plugin.js';
import vuetify from '@/Plugins/vuetify.js';
import VideoPlayer from '@/Store/VideoPlayer.js';
import Meet from '@/Models/Meet.js';
import Result from '@/Models/Result.js';
import Session from '@/Models/Session.js';
import Flight from '@/Models/Flight.js';
import StartList from '@/Models/StartList.js';
import ResultCategory from '@/Models/ResultCategory.js';
import TeamDefinitionSetting from '@/Models/TeamDefinitionSetting.js';

import Athlete from '@/Models/Athlete.js';
import AthleteFollow from '@/Models/AthleteFollow.js';
import ClubFollow from '@/Models/ClubFollow.js';
import Club from '@/Models/Club.js';
import TrampolineScore from '@/Models/TrampolineScore.js';
import DoubleMiniScore from '@/Models/DoubleMiniScore.js';
import TumblingScore from '@/Models/TumblingScore.js';
import SynchroScore from '@/Models/SynchroScore.js';
import TrampolineSkillDeductions from '@/Models/TrampolineSkillDeductions.js';
import DoubleMiniSkillDeductions from '@/Models/DoubleMiniSkillDeductions.js';
import TumblingSkillDeductions from '@/Models/TumblingSkillDeductions.js';
import SynchroSkillDeductions from '@/Models/SynchroSkillDeductions.js';
import Media from '@/Models/Media.js';
import User from '@/Models/User.js';
import Role from '@/Models/Role.js';
import Affiliation from '@/Models/Affiliation.js';
// import DatetimePicker from 'vuetify-datetime-picker';
import TeamQualifier from '@/Models/TeamQualifier.js';
import MeetTeamQualifier from '@/Models/MeetTeamQualifier.js';
import Video from '@/Models/Video.js';
import FlightResultCategory from '@/Models/FlightResultCategory.js';

import '@/bootstrap.js';
import TrampolineMobilitySkill from '@/Models/TrampolineMobilitySkill.js';
import TumblingMobilitySkill from '@/Models/TumblingMobilitySkill.js';
import DoubleMiniMobilitySkill from '@/Models/DoubleMiniMobilitySkill.js';
import DoubleMiniSkill from '@/Models/DoubleMiniSkill.js';
import Subscription from '@/Models/Subscription.js';
import Coupon from '@/Models/Coupon.js';
import UploadVideo from '@/Models/UploadVideo.js';
import Country from '@/Models/Country.js';
import Activity from '@/Models/Activity.js';
import ActivityLogItem from '@/Models/ActivityLogItem.js';
import NotificationPreferences from '@/Models/NotificationPreferences.js';
import Job from '@/Models/Job.js';
import JobApplication from '@/Models/JobApplication.js';
import Announcement from '@/Models/Announcement';

Vue.config.productionTip = false;

Vue.config.devtools =
    typeof import.meta.env.VITE_APP_DEBUG === 'string'
        ? import.meta.env.VITE_APP_DEBUG === 'true'
        : import.meta.env.VITE_APP_DEBUG;

Sentry.init({
    Vue,
    dsn: 'https://ea53fc0219a0490cbbcf7e07133b79b3@o353433.ingest.sentry.io/5427609',
    integrations: [new Integrations.BrowserTracing()],
    logErrors: true,
});

// Reverb
Vue.prototype.$echo = new Echo({
    broadcaster: 'reverb',
    key: import.meta.env.VITE_REVERB_APP_KEY,
    wsHost: import.meta.env.VITE_REVERB_HOST,
    wsPort: import.meta.env.VITE_REVERB_PORT ?? 80,
    wssPort: import.meta.env.VITE_REVERB_PORT ?? 443,
    forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
});

// Pusher
// Vue.prototype.$echo = new Echo({
//     broadcaster: 'pusher',
//     key: window.configs.pusher.key,
//     cluster: window.configs.pusher.cluster,
//     forceTLS: window.configs.pusher.forceTLS,
//     namespace: 'App.Events',
// });

Vue.use(VueFullscreen);
Vue.use(VueSocialSharing);
Vue.use(VueClipboard);
Vue.use(VueScrollTo);

Vue.use(CookieConsent);

Vue.component('RouterLink', {
    functional: true,
    render(h, context) {
        const data = { ...context.data };
        delete data.nativeOn;
        const props = data.props || {};
        props.href = props.to; /// v-btn passes `to` prop but inertia-link requires `href`, so we just copy it
        return h('inertia-link', data, context.children);
    },
});

/**
 * Imports the given page component from the page record.
 */
function resolvePageComponent(name, pages) {
    const pageName = `${name.replace('.', '/')}.vue`;

    const foundPath = Object.keys(pages).find(path => path.replace('./Pages/', '') === pageName);

    if (foundPath) {
        return typeof pages[foundPath] === 'function' ? pages[foundPath]() : pages[foundPath];
    }

    throw new Error(`Page not found: ${name}`);
}

createInertiaApp({
    resolve: name => {
        return resolvePageComponent(name, import.meta.glob('./Pages/**/*.vue'));
    },
    progress: {
        color: '#29d',
    },
    setup({ el, App, props, plugin }) {
        Vue.use(plugin);
        Vue.use(ZiggyPlugin);

        Vue.component('InertiaLink', Link);

        Vue.mixin({
            computed: {
                $routeParams() {
                    return Object.fromEntries(new URLSearchParams(window.location.search));
                },
            },
        });

        Vue.use(Vuex);
        Vue.use(PortalVue);
        Vue.use(VueMoment, { moment });
        // Vue.use(DatetimePicker);

        window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        window.axios.defaults.withCredentials = true;

        VuexORM.use(VuexORMAxios, {
            axios,
            baseURL: '/',
            dataKey: 'data',
        });

        const database = new VuexORM.Database();

        database.register(User);
        database.register(Role);
        database.register(Media);
        database.register(Meet);
        database.register(Result);
        database.register(TrampolineScore);
        database.register(DoubleMiniScore);
        database.register(TumblingScore);
        database.register(SynchroScore);
        database.register(Athlete);
        database.register(AthleteFollow);
        database.register(ClubFollow);
        database.register(Club);
        database.register(ResultCategory);
        database.register(Affiliation);
        database.register(Session);
        database.register(Flight);
        database.register(StartList);
        database.register(TeamQualifier);
        database.register(MeetTeamQualifier);
        database.register(Video);
        database.register(FlightResultCategory);
        database.register(TrampolineMobilitySkill);
        database.register(TumblingMobilitySkill);
        database.register(DoubleMiniMobilitySkill);
        database.register(DoubleMiniSkill);
        database.register(Subscription);
        database.register(Coupon);
        database.register(TeamDefinitionSetting);
        database.register(UploadVideo);
        database.register(Country);
        database.register(TrampolineSkillDeductions);
        database.register(DoubleMiniSkillDeductions);
        database.register(TumblingSkillDeductions);
        database.register(SynchroSkillDeductions);
        database.register(Activity);
        database.register(ActivityLogItem);
        database.register(NotificationPreferences);
        database.register(Job);
        database.register(JobApplication);
        database.register(Announcement);

        const store = new Vuex.Store({
            plugins: [VuexORM.install(database)],

            modules: {
                VideoPlayer,
            },

            state: {
                debugMode: false,
                forceHardNavigation: false,
                globalVideoPlayer: {},
            },

            mutations: {
                SET_DEBUG_MODE(state, debug) {
                    state.debugMode = debug;
                },

                SET_FORCE_HARD_NAVIGATION(state, force) {
                    state.forceHardNavigation = force;
                },

                SET_GLOBAL_VIDEO_PLAYER(state, player) {
                    state.globalVideoPlayer = player;
                },
            },
        });

        router.on('before', event => {
            if (store.state.forceHardNavigation) {
                event.preventDefault();
                window.location = event.detail.visit.url.href;
            }
        });

        Vue.prototype.$score = (score, format = '0.0') => {
            return numeral(score).format(format);
        };

        Vue.prototype.$isIOS = window.iPhoneApp || false;

        Vue.prototype.$isDevEnv = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'local';

        Vue.prototype.openIOSLink = url => {
            if (!Vue.prototype.$isIOS) {
                window.location.href = url;

                return;
            }

            // window.webkit.messageHandlers.interceptor.postMessage({
            //     type: 'nativeFunction',
            //     data: 'test',
            // });

            window.webkit.messageHandlers.openExternalLink.postMessage(url);
        };

        new Vue({
            render: h => h(App, props),
            store,
            vuetify,
        }).$mount(el);
    },
});
