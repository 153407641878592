import { Dropbox } from 'dropbox';
import find from 'lodash/find.js';
import levels from '@/levels.js';
import gradients from '@/gradients.js';

export function ordinal(number) {
    const j = number % 10;
    const k = number % 100;

    if (j === 1 && k !== 11) {
        return `${number}st`;
    }

    if (j === 2 && k !== 12) {
        return `${number}nd`;
    }

    if (j === 3 && k !== 13) {
        return `${number}rd`;
    }

    return `${number}th`;
}

export function toQueryString(params) {
    return Object.keys(params)
        .filter(key => params[key] !== undefined && params[key] !== '')
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');
}
function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

export function formatJSDate(date) {
    return `${[date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join('-')} ${[
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
    ].join(':')}`;
}

export function eventFromProscore($event, length = 'short') {
    if (length === 'short') {
        return {
            TI: 'tra',
            DM: 'dmt',
            TU: 'tum',
            TS: 'syn',
        }[$event];
    }

    return {
        TI: 'trampoline',
        DM: 'double-mini',
        TU: 'tumbling',
        TS: 'synchro',
    }[$event];
}

export function routineRelationship($event) {
    return {
        TI: 'trampolineScores',
        DM: 'doubleMiniScores',
        TU: 'tumblingScores',
        TS: 'synchroScores',
    }[$event];
}

export function level(identifier) {
    return find(levels, ['short', identifier]);
}

export function uploadFileToDropbox(accessToken, file, resultsEl) {
    const UPLOAD_FILE_SIZE_LIMIT = 150 * 1024 * 1024;
    const dbx = new Dropbox({ accessToken });

    if (file.size < UPLOAD_FILE_SIZE_LIMIT) {
        // File is smaller than 150 Mb - use filesUpload API
        dbx.filesUpload({ path: `/${file.name}`, contents: file })
            .then(function upload(response) {
                const br = document.createElement('br');
                resultsEl.appendChild(document.createTextNode('File uploaded!'));
                resultsEl.appendChild(br);
                // eslint-disable-next-line no-console
                console.log(response);
            })
            .catch(function uploadError(error) {
                // eslint-disable-next-line no-console
                console.error(error);
            });
    } else {
        // File is bigger than 150 Mb - use filesUploadSession* API
        const maxBlob = 8 * 1000 * 1000; // 8Mb - Dropbox JavaScript API suggested max file / chunk size

        const workItems = [];

        let offset = 0;

        while (offset < file.size) {
            const chunkSize = Math.min(maxBlob, file.size - offset);
            workItems.push(file.slice(offset, offset + chunkSize));
            offset += chunkSize;
        }

        const task = workItems.reduce((acc, blob, idx, items) => {
            if (idx === 0) {
                // Starting multipart upload of file
                return acc.then(function startUploadSession() {
                    return dbx
                        .filesUploadSessionStart({ close: false, contents: blob })
                        .then(response => response.session_id);
                });
            }
            if (idx < items.length - 1) {
                // Append part to the upload session
                return acc.then(function appendUploadSession(sessionId) {
                    const cursor = { session_id: sessionId, offset: idx * maxBlob };
                    return dbx
                        .filesUploadSessionAppendV2({ cursor, close: false, contents: blob })
                        .then(() => sessionId);
                });
            }
            // Last chunk of data, close session
            return acc.then(function finishUploadSesion(sessionId) {
                const cursor = { session_id: sessionId, offset: file.size - blob.size };
                const commit = { path: `/${file.name}`, mode: 'add', autorename: true, mute: false };
                return dbx.filesUploadSessionFinish({ cursor, commit, contents: blob });
            });
        }, Promise.resolve());

        task.then(function finishUploadTask(result) {
            // eslint-disable-next-line no-console
            console.log(result);
            resultsEl.appendChild(document.createTextNode('File uploaded!'));
        }).catch(function taskError(error) {
            // eslint-disable-next-line no-console
            console.error(error);
        });
    }
    return false;
}

export function formatPhoneNumber(phoneNumberString) {
    const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        const intlCode = match[1] ? '+1 ' : '';

        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }

    return null;
}

export function avatarColor(firstName = '', lastName = '') {
    const colors = gradients;

    const name = firstName + lastName;

    const sum = _.sum(name.split('').map(letter => letter.charCodeAt(0)));

    return colors[sum % colors.length];
}

export function blobToBase64(blob) {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}
