function loadGoogleAnalytics() {
    // Load the Google Analytics script
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-PZ0FV2MQ55';

    document.head.appendChild(script);

    script.onload = function () {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            window.dataLayer.push(arguments);
        }
        gtag('js', new Date());

        gtag('config', 'G-PZ0FV2MQ55', { anonymize_ip: true });

        console.log('Google Analytics loaded');
    };
}

/**
 * @type {import('vanilla-cookieconsent').CookieConsentConfig}
 */
const config = {
    revision: 1,

    guiOptions: {
        consentModal: {
            layout: 'box inline',
            position: 'bottom right',
        },
        preferencesModal: {
            layout: 'box',
        },
    },

    onConsent: category => {
        if (category?.cookie?.categories?.includes('analytics')) {
            loadGoogleAnalytics();
        }
    },

    onChange: category => {
        if (category?.cookie?.categories?.includes('analytics')) {
            loadGoogleAnalytics();
        }
    },

    categories: {
        necessary: {
            readOnly: true,
            enabled: true,
        },
        analytics: {
            autoClear: {
                cookies: [
                    {
                        name: /^(_ga|_gid)/,
                    },
                ],
            },
        },
        ads: {},
    },

    language: {
        default: 'en',

        translations: {
            en: {
                consentModal: {
                    title: 'Cookie preferences',
                    description:
                        'Our website uses essential cookies to ensure its proper operation. We also use analytics cookies to understand site usage and improve your experience. These cookies do not track you across other websites. Consent is required for analytics cookies, which you can manage here.',
                    acceptAllBtn: 'Accept',
                    acceptNecessaryBtn: 'Reject',
                    showPreferencesBtn: 'Manage preferences',
                    closeIconLabel: 'Reject all and close',
                    footer: `
                      <a href="/privacy-policy">Privacy Policy</a>
                      <a href="/terms-of-service">Terms of Service</a>
                  `,
                },
                preferencesModal: {
                    title: 'Cookie preferences',
                    acceptAllBtn: 'Accept all',
                    acceptNecessaryBtn: 'Reject all',
                    savePreferencesBtn: 'Save preferences',
                    sections: [
                        {
                            title: 'Cookie usage',
                            description:
                                'We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want.',
                        },
                        {
                            title: 'Strictly necessary cookies',
                            description:
                                'These cookies are essential for the website to function properly and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.',
                            linkedCategory: 'necessary',
                        },
                        {
                            title: 'Performance and analytics cookies',
                            description:
                                'These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance.',
                            linkedCategory: 'analytics',
                            cookieTable: {
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Domain',
                                    desc: 'Description',
                                },
                                body: [
                                    {
                                        name: '_ga',
                                        domain: 'scorsync.com',
                                        desc: 'Google Analytics cookie',
                                    },
                                    {
                                        name: '_gid',
                                        domain: 'scorsync.com',
                                        desc: 'Google Analytics cookie',
                                    },
                                ],
                            },
                        },
                        {
                            title: 'More information',
                            description:
                                'For any queries in relation to our policy on cookies and your choices, please <a class="cc__link" href="mailto:support@scorsync.com">contact us</a>.',
                        },
                    ],
                },
            },
        },
    },
};

export default config;
