import Model from '@/Models/Model.js';
import Athlete from '@/Models/Athlete.js';
import Job from '@/Models/Job.js';
import gradients from '@/gradients.js';
import Country from '@/Models/Country.js';

export default class Club extends Model {
    static entity = 'clubs';

    constructor(record) {
        super(record);

        this.follow_entity = 'club';
    }

    static fields() {
        return {
            id: this.attr(null),
            country_id: this.attr(null),
            usag_number: this.attr(null),
            name: this.attr(null),
            profile_photo_path: this.attr(null),
            profile_photo_url: this.attr(null),
            athletes_count: this.number(0),
            created_at: this.string(null),
            updated_at: this.string(null),
            entity: this.string('club'),

            athletes: this.hasMany(Athlete, 'club_id'),
            country: this.belongsTo(Country, 'country_id'),
            jobs: this.hasMany(Job, 'club_id'),
        };
    }

    get avatar_color() {
        const colors = gradients;

        const sum = _.sum(this.name.split('').map(letter => letter.charCodeAt(0)));

        return colors[sum % colors.length];
    }

    get link() {
        return `/clubs/${this.id}`;
    }

    get initials() {
        if (this.country_id && this.country) {
            return this.country.ioc;
        }

        const words = this.name.split(' ');

        if (words.length > 1) {
            return _.first(words).charAt(0).toUpperCase() + _.last(words).charAt(0).toUpperCase();
        }

        return this.name.charAt(0).toUpperCase() + this.name.charAt(1).toUpperCase();
    }
}
