// plugins/cookieconsentvue.js for Vue 2
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import * as CookieConsent from 'vanilla-cookieconsent';
import config from './cookieconsent-config.js';

const CookieConsentVue = {
    install(Vue) {
        CookieConsent.run(config); // Use init instead of run for Vue 2 compatibility
        Vue.prototype.$CC = CookieConsent; // Attach CookieConsent to Vue's prototype
    },
};

export default CookieConsentVue;
